import React, { useState, useCallback } from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { redirect } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import Sidebar from './Sidebar';
import Dropzone from 'react-dropzone';
import './dashboard.css';
import AWS from 'aws-sdk';

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
  region: 'us-east-2'
});


function Dashboard() {
  const { isAuthenticated, user } = useAuth0();

  const [duelInfo, setDuelInfo] = useState({
    name: '',
    description: '',
    opponent1: '',
    opponent2: '',
    endDate: ''
  });

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [op1mainpic, setop1mainpic] = useState(null);
  const [isOp1MainPicLoading, setIsOp1MainPicLoading] = useState(false);
  const [op2mainpic, setop2mainpic] = useState(null);
  const [isOp2MainPicLoading, setIsOp2MainPicLoading] = useState(false);

  const [op1ImageDimensions, setOp1ImageDimensions] = useState({ width: 0, height: 0 });
  const [op2ImageDimensions, setOp2ImageDimensions] = useState({ width: 0, height: 0 });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createdUrl, setCreatedUrl] = useState('');



  //create duel call to https://gi1k8oss55.execute-api.us-east-2.amazonaws.com/production/createDuel
  const createDuel = async () => {
    const response = await fetch('https://gi1k8oss55.execute-api.us-east-2.amazonaws.com/production/createDuel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        opponent1name: duelInfo.opponent1,
        opponent2name: duelInfo.opponent2,
        endtime: duelInfo.endDate,
        opponent1picture: op1mainpic,
        opponent2picture: op2mainpic,
        opponent1dimensions: op1ImageDimensions,
        opponent2dimensions: op2ImageDimensions,
        //oauth user id
        userid: user.sub
      })
    });
    return response;
  }

  const onDropOp1MainPic = useCallback((acceptedFiles) => {
    // loading
    setIsOp1MainPicLoading(true);
    const file = acceptedFiles[0];
    const reader = new FileReader();
  
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
  
      img.onload = () => {
        setOp1ImageDimensions({ width: img.width, height: img.height });

        // Convert .webp to .jpg if necessary
        const convertImage = (image, callback) => {
          if (file.type === "image/webp") {
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0);
            canvas.toBlob(callback, "image/jpeg");
          } else {
            const blob = new Blob([file], { type: file.type });
            const blobToFile = new File([blob], file.name, { type: file.type, lastModified: file.lastModified });
            callback(blobToFile);
          }
        };
  
        convertImage(img, (convertedFile) => {
          const fileName = file.type === "image/webp" ? file.name.replace(".webp", ".jpg") : file.name;

          const params = {
            Bucket: "dueldayimages",
            Key: `opponent1_secondary_${Date.now()}_${fileName}`,
            Body: convertedFile,
            ACL: "public-read"
          };
  
          s3.upload(params, (err, data) => {
            if (err) {
              console.log(err);
              // loaded
              setIsOp1MainPicLoading(false);
            } else {
              console.log(data.Location);
              setop1mainpic(data.Location);
              // loaded
              setIsOp1MainPicLoading(false);
            }
          });
        });
  
      };
    };
  
    reader.readAsDataURL(file);
  }, []);
  

  const onDropOp2MainPic = useCallback((acceptedFiles) => {
    // loading
    setIsOp2MainPicLoading(true);
    const file = acceptedFiles[0];
    const reader = new FileReader();
  
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
  
      img.onload = () => {
        setOp2ImageDimensions({ width: img.width, height: img.height });

        // Convert .webp to .jpg if necessary
        const convertImage = (image, callback) => {
          if (file.type === "image/webp") {
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0);
            canvas.toBlob(callback, "image/jpeg");
          } else {
            const blob = new Blob([file], { type: file.type });
            const blobToFile = new File([blob], file.name, { type: file.type, lastModified: file.lastModified });
            callback(blobToFile);
          }
        };
  
        convertImage(img, (convertedFile) => {
          const fileName = file.type === "image/webp" ? file.name.replace(".webp", ".jpg") : file.name;

          const params = {
            Bucket: "dueldayimages",
            Key: `opponent2_secondary_${Date.now()}_${fileName}`,
            Body: convertedFile,
            ACL: "public-read"
          };
  
          s3.upload(params, (err, data) => {
            if (err) {
              console.log(err);
              // loaded
              setIsOp2MainPicLoading(false);
            } else {
              console.log(data.Location);
              setop2mainpic(data.Location);
              // loaded
              setIsOp2MainPicLoading(false);
            }
          });
        });
      };
    };
  
    reader.readAsDataURL(file);
  }, []);
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDuelInfo(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Set isSubmitting to true when the form submission starts
  
    // Call API to create duel with duelInfo data
    let data = await createDuel();
    console.log(data);
    // if status code is 200, set isSuccess to true
    if (data.status === 200) {
      // clear form
      setop1mainpic(null);
      setop2mainpic(null);
      setIsSuccess(true);
      setIsError(false);
      mixpanel.track("Duel Created", {
        "Duel Link": 'https://duel.day/' + duelInfo.opponent1 + "vs" + duelInfo.opponent2
      });
      setCreatedUrl('https://duel.day/' + duelInfo.opponent1 + "vs" + duelInfo.opponent2);
    } else {
      setIsError(true);
      setIsSuccess(false);
      mixpanel.track("Duel Creation Error", {
        "Duel Info": duelInfo,
      });
    }
  
    setIsSubmitting(false); // Set isSubmitting to false when the form submission ends
  };
  

  if (!isAuthenticated) {
    return <redirect to="/" />;
  }

  //issubmitdisabled
  const isSubmitDisabled = !duelInfo.opponent1 || !duelInfo.opponent2 || !duelInfo.endDate || !op1mainpic || !op2mainpic;

  return (
    <div className="dashboard">
      <Sidebar 
        user={user}
      />
      <div className="main-content">
        <div className="container">
        <div className="card3223 p-4">
          <h1 className="page-title">Create a Duel</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Duel Name:</label>
              <div className="d-flex justify-content-between align-items-center">
                <input type="text" id="opponent1" name="opponent1" placeholder="Enter Name"
                onChange={handleInputChange} 
                className="form-control" />
                <span className="mx-3">vs</span>
                <input type="text" id="opponent2" name="opponent2" placeholder="Enter Name" 
                onChange={handleInputChange} 
                className="form-control" />
              </div>
              <div className="info-text">
                {/* <p>{process.env.REACT_APP_AWS_THISISATES}</p> */}
                <p>Your Duel URL will be: http://duel.day/{
                  //clean out the spaces
                  duelInfo.opponent1.replace(/\s/g, '')
                }vs{duelInfo.opponent2.replace(/\s/g, '')}</p>
              </div>
            </div>
            {/* <div className="form-group">
              <label htmlFor="description">Description:</label>
              <textarea
                id="description"
                name="description"
                value={duelInfo.description}
                onChange={handleInputChange}
              ></textarea>
            </div> */}
            <div className="mb-3">
              <label htmlFor="endDate" className="form-label">
                End Time:
              </label>
              <input
                type="datetime-local"
                className="form-control"
                id="endDate"
                name="endDate"
                value={duelInfo.endDate}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="opponent1">
                {(duelInfo && duelInfo.opponent1) ? duelInfo.opponent1 : "Opponent #1"} Main Picture:
                </label>
              <Dropzone onDrop={onDropOp1MainPic}>
                {({ getRootProps, getInputProps }) => (
                  <div
                    className="dropzone"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {isOp1MainPicLoading && <p>Loading...</p>}
                    {!isOp1MainPicLoading && <p>Drag and drop an image here, or click to select a file</p>}
                    {op1mainpic && <img src={op1mainpic} alt="Preview" />}
                  </div>
                )}
              </Dropzone>
            </div>
            <div className="form-group">
              <label htmlFor="opponent1">
                {(duelInfo && duelInfo.opponent2) ? duelInfo.opponent2 : "Opponent #2"} Main Picture:
                </label>
              {/* info text */}
              <Dropzone onDrop={onDropOp2MainPic}>
                {({ getRootProps, getInputProps }) => (
                  <div
                    className="dropzone"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {isOp2MainPicLoading && <p>Loading...</p>}
                    {!isOp2MainPicLoading && <p>Drag and drop an image here, or click to select a file</p>}
                    {op2mainpic && <img src={op2mainpic} alt="Preview" />}
                  </div>
                )}
              </Dropzone>
            </div>
            <button
              onClick={handleSubmit}
              disabled={isSubmitDisabled || isSubmitting}
              type="submit"
              className="btn btn-primary"
            >
              {isSubmitting ? (
                <svg
                  className="spinner"
                  viewBox="0 0 50 50"
                  width="24px"
                  height="24px"
                  style={{ marginRight: '8px' }}
                >
                  <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                </svg>
              ) : null}
              {isSubmitDisabled ? "Please fill out all fields" : "Create Duel"}
            </button>
          </form>
          {isSuccess && <p className="success-message">Duel created successfully! <br/> 
          👉 <a 
          style={{color: "blue"}}
          href={createdUrl.replace(/\s/g, '')} target="_blank" rel="noreferrer">{createdUrl.replace(/\s/g, '')}</a></p>}
          {isError && <p className="error-message">There was an error creating your duel. Make sure duel URL is not taken. If not, please try again.</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuthenticationRequired(Dashboard);
