/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://5whuqrlg2jgrtc52bzk6emt5nq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6dl4b3dw25dwjpwmgsrtcu7cru",
    "aws_cloud_logic_custom": [
        {
            "name": "dueldayAPI",
            "endpoint": "https://gi1k8oss55.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
