import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import mixpanel from "mixpanel-browser";
//import contest css
import './contest.css';

//call amplify to get contest from db, using slug
import { API } from 'aws-amplify';
import { Helmet } from 'react-helmet-async';


//get contest
async function getContest(slug) {
    const apiName = 'dueldayAPI';
    const path = '/getDuel';
    const myInit = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: {
            slug: slug.toLowerCase()
        }
    };
    let contest = await API.post(apiName, path, myInit);
    return contest;
}



function Contest() {
    //variables for time, days, hours, minutes, seconds
    const [days, setDays] = useState('00');
    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00');
    const [seconds, setSeconds] = useState('00');

    const [loading, setLoading] = useState(true);

    let { slug } = useParams();
    let [contestData, setcontestData] = useState(null);

    const [team1Products, setTeam1Products] = useState([]);
    const [team2Products, setTeam2Products] = useState([]);


    //call getContest function to get contest from db
    useEffect(() => {
        getContest(slug).then((contest) => {
            //if item is not found, redirect to home page
            if (contest.Items.length === 0) {
                window.location.href = '/';
            }

            const contestItem = contest.Items[0];
            const productMappingData = JSON.parse(contestItem.productMapping.S);

            setTeam1Products(productMappingData.team1);
            setTeam2Products(productMappingData.team2);

            //set payment links to first
            console.log("PRODUCTMAPPINGDATA");
            console.log(productMappingData.team1);
            // if product variant exists and is more than 1, set the payment link to the first one
            if (productMappingData.team1[0].product_variants.length > 1) {
                setSelectedLinkCh1(productMappingData.team1[0].product_variants[1].variant_payment_link);
            } else {
                setSelectedLinkCh1(productMappingData.team1[0].product_variants[0].variant_payment_link);
            }
            if (productMappingData.team2[0].product_variants.length > 1) {
                setSelectedLinkCh2(productMappingData.team2[0].product_variants[1].variant_payment_link);
            } else {
                setSelectedLinkCh2(productMappingData.team2[0].product_variants[0].variant_payment_link);
            }

            setSelectedVariantCh1(productMappingData.team1.map((product) => (product.product_variants.length > 1 ? 1 : 0)));
            setSelectedVariantCh2(productMappingData.team2.map((product) => (product.product_variants.length > 1 ? 1 : 0)));
            // contestItem.paymentLink1 = {productMappingData.team1};

            setcontestData(contestItem);
            setLoading(false);
        });
    }, []);

    //countdown function and every second it will update the time
    const countdown = () => {
        const countDate = new Date(contestData.end_datetime.S).getTime();
        const now = new Date().getTime();
        const gap = countDate - now;

        //calculations for time
        const second = 1000;
        const minute = second * 60;
        const hour = minute * 60;
        const day = hour * 24;

        //calculations for days, hours, minutes, seconds
        const textDay = Math.floor(gap / day);
        const textHour = Math.floor((gap % day) / hour);
        const textMinute = Math.floor((gap % hour) / minute);
        const textSecond = Math.floor((gap % minute) / second);

        //set the time
        setDays(textDay);
        setHours(textHour);
        setMinutes(textMinute);
        setSeconds(textSecond);
    }
    
    //useEffect to update the time every second
    useEffect(() => {
        if (contestData){
            setInterval(countdown, 1000);
        }
    }, [contestData]);

    useEffect(() => {
        mixpanel.track("Page View", { 
            page_name: slug,
         });
      }, []);

    const [selectedProductCh1, setSelectedProductCh1] = useState(0);
    const [selectedProductCh2, setSelectedProductCh2] = useState(0);
    const [team1Percentage, setTeam1Percentage] = useState(0);

    //calculate score when contestData is loaded
    useEffect(() => {
        if (contestData) {
            //conver strings to integers
            let team1Score = parseInt(contestData.team1_score.N);
            let team2Score = parseInt(contestData.team2_score.N);
            //calculate percentage
            let team1Percentage = (team1Score / (team1Score + team2Score)) * 100;
            //floor
            setTeam1Percentage(Math.floor(team1Percentage));
        }
    }, [contestData]);

    //selectedlinks
    const [selectedLinkCh1, setSelectedLinkCh1] = useState('');
    const [selectedLinkCh2, setSelectedLinkCh2] = useState('');

    const [selectedVariantCh1, setSelectedVariantCh1] = useState([]);
    const [selectedVariantCh2, setSelectedVariantCh2] = useState([]);

    
    function handleProductButtonClick1(index) {
        setSelectedProductCh1(index);
        setSelectedLinkCh1(team1Products[index].product_variants[
            selectedVariantCh1[index]
        ].variant_payment_link);
    }
    
    function handleProductButtonClick2(index) {
        setSelectedProductCh2(index);
        setSelectedLinkCh2(team2Products[index].product_variants[
            selectedVariantCh2[index]
        ].variant_payment_link);
    }
    

    function handleVariantChangeCh1(event, productIndex) {
        const variantIndex = parseInt(event.target.value);
        setSelectedVariantCh1((prevState) => {
            const newState = [...prevState];
            newState[productIndex] = variantIndex;
            return newState;
        });
        setSelectedLinkCh1(team1Products[productIndex].product_variants[variantIndex].variant_payment_link);
    }
    
    function handleVariantChangeCh2(event, productIndex) {
        const variantIndex = parseInt(event.target.value);
        setSelectedVariantCh2((prevState) => {
            const newState = [...prevState];
            newState[productIndex] = variantIndex;
            return newState;
        });
        setSelectedLinkCh2(team2Products[productIndex].product_variants[variantIndex].variant_payment_link);
    }
    
    if (loading) {
        return (
            <div className="zuzu-preloader">
                <div className="preloader-square-swapping">
                    <div className="cssload-square-part cssload-square-green"></div>
                    <div className="cssload-square-part cssload-square-pink"></div>
                    <div className="cssload-square-blend"></div>
                </div>
            </div>
        );
    }

    const ContestURL = "https://duel.day/" + slug;

    const title = contestData.team1_name.S + " vs " + contestData.team2_name.S + " | " + 'Duel Day';
    const description = "🚀 Epic Showdown! " + contestData.team1_name.S + " vs. " + contestData.team2_name.S + " 🥊 Which side are you on? Join the frenzy and place your bets! 😂🎉";    ;
    
    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={contestData.team1_img.S} />
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            <div id="__nuxt">
                <div id="__layout">
                    <div id="__page">
                        <div id="__wrapper-outer">
                            <div id="__wrapper">
                                <div className="__noise"></div>
                                <div id="__nav-wrapper">
                                    <div className="__nav-elem">
                                        {/* <img src="/images/lore-icon.svg" className="__nav-elem-icon"></img>  */}
                                        <span className="desktop-only">TEAM {contestData.team1_name.S} ⚡</span> 
                                        <span className="mobile-only">{contestData.team1_name.S}</span>
                                    </div>
                                    <div className="__divider-vert"></div>
                                    <div className="__nav-elem"
                                    onClick={() => {
                                        window.location.href = '/';
                                    }}
                                    >
                                        {/* <img src="/images/lore-icon.svg" className="__nav-elem-icon"></img>  */}
                                        {/* <span className="desktop-only">DUEL.DAY ⚔️</span>  */}
                                        {/* <span className="mobile-only">DUEL.DAY ⚔️</span> */}
                                        <span className="desktop-only">DUEL.DAY</span>
                                        <span className="mobile-only">DUEL.DAY</span>
                                    </div>
                                    <div className="__divider-vert"></div>
                                    <div className="__nav-elem">
                                        {/* <img src="/images/lore-icon.svg" className="__nav-elem-icon"></img>  */}
                                        <span className="desktop-only">TEAM {contestData.team2_name.S} 🔥</span> 
                                        <span className="mobile-only">{contestData.team2_name.S}</span>
                                    </div>
                                </div>
                                <div className="__divider-hor"></div>
                                <div id="__main">
                                    <div className='__illustration-wrapper'>
                                        <img src={contestData.team1_img.S} className="__illustration"></img>
                                        <div className="group_below">
                                        <div className="button-group">
                                            {team1Products.map((product, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() => handleProductButtonClick1(index)}
                                                    className={`productbutton${selectedProductCh1 === index ? ' pselected' : ''}`}
                                                >
                                                    <img
                                                        src={product.product_image}
                                                        alt={product.product_name}
                                                        width="125"
                                                        height="125"
                                                        style={{ objectFit: 'cover' }}
                                                    />
                                                    {product.product_name}: ${product.product_price}
                                                    {product.product_variants.length > 1 && (
                                                        <select
                                                            className="form-select"
                                                            aria-label="Default select example"
                                                            onChange={(event) => handleVariantChangeCh1(event, index)}
                                                        >
                                                            {product.product_variants.map((variant, variantIndex) => (
                                                                <option 
                                                                selected={variantIndex === 1}
                                                                key={variantIndex} value={variantIndex}>
                                                                    {variant.variant_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                </button>
                                            ))}
                                        </div>

                                        <div className="_bottom-btns desktop-btn" style={{ opacity: '1' }}>
                                            <a href={selectedLinkCh1} className="__get-btn1">
                                                Support {contestData.team1_name.S}
                                            </a>
                                        </div>
                                    </div>

                                        <div className="_bottom-btns mobile-btn" style={{opacity: '1'}}><a href={selectedLinkCh1} className="__get-btn1">Support {contestData.team1_name.S}</a></div>
                                    </div>
                                    <div className='__divider-vert __divider-vert-left'></div>
                                    <div id='__main-page'>
                                        <div>
                                            <div className="__fade-wrapper">
                                                <div className="__fade"></div>
                                            </div>
                                            <div id="#__main-page-nuxt">
                                                <div className="status">
                                                    <div className="card2">
                                                        <div className="card-desc">
                                                            {/* <div className="card-desc-top">
                                                            </div> */}
                                                            <div className="card-desc-bottom">
                                                                <span>Join the battle!<br/> <br/> <br/> <br/> The current score is:</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bar">
                                                    <div className="progress">
                                                        <div className="progress-bar" role="progressbar" style={{width: team1Percentage+"%", borderRadius:"10px", backgroundColor:'#22bdff'}} aria-valuenow={toString(Math.floor((900 / 10000) * 100))} aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    </div>
                                                    <div className="progress-text float-container">
                                                        <h4 className='float-child' style={{color: 'white'}}>${contestData.team1_score.N}</h4>
                                                        <h6 className='float-child' style={{color: 'white', marginTop:'12px'}}>vs</h6>
                                                        <h4 className='float-child' style={{color: 'white'}}>${contestData.team2_score.N}</h4>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card-desc">
                                                            <div className="card-desc-bottom">
                                                                <span>The team with the most purchases at the end of the contest will be declared the winner.</span> <br/><br/>
                                                                {/* <span>The team with the most purchases at the end of the contest wins.</span> <br/><br/> */}
                                                                {/* <span>Who will come out on top?</span><br/><br/> */}
                                                                {/* <span>Only time will tell.</span> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card2">
                                                        <div className="card-desc">
                                                            <div className="card-desc-top">
                                                            </div>
                                                            <div className="card-desc-bottom">
                                                                <span style={{
                                                                    fontSize: '30px',
                                                                    whiteSpace: 'nowrap',
                                                                }}>one catch</span>
                                                                <br/>
                                                                <span>LOSER GETS THE MERCH OF THE WINNER</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-desc-bottom" style={{marginTop: '50px'}}>
                                                        YOU HAVE UNTIL:
                                                    </div>
                                                    <div id="timer" style={{marginTop: '20px'}}>
                                                        <div> {days} <span>days</span></div>
                                                        <div> {hours} <span>hours</span></div>
                                                        <div> {minutes} <span>minutes</span></div>
                                                        <div> {seconds} <span>seconds</span></div>
                                                    </div>
                                                    <div className="card-desc-bottom" style={{ marginTop: "50px" }}>
  🚀 BOOST YOUR TEAM'S SCORE 🚀
</div>
<div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
  <a 
  //using the ContestURL variable to share the contest URL
  href={'https://www.facebook.com/sharer/sharer.php?u=' + ContestURL}
  target="_blank" className="social-share-button">
    <i className="fab fa-facebook-f"></i>
  </a>
  <a href={'https://twitter.com/intent/tweet?url='+ContestURL+'&text=Join%20the%20battle%20and%20support%20your%20team%20in%20this%20epic%20contest!'} target="_blank" className="social-share-button">
    <i className="fab fa-twitter"></i>
  </a>
  <a href={'https://wa.me/?text=Join%20the%20battle%20and%20support%20your%20team%20in%20this%20epic%20contest!%20'+ContestURL} target="_blank" className="social-share-button">
    <i className="fab fa-whatsapp"></i>
  </a>
  <a href={'mailto:?subject=Join%20the%20Battle%20in%20this%20Epic%20Contest&body=Check%20out%20this%20awesome%20contest%20and%20support%20your%20team!%20'+ContestURL} target="_blank" className="social-share-button">
    <i className="fas fa-envelope"></i>
  </a>
</div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='__divider-vert __divider-vert-right'></div>
                                    <div className='__illustration-wrapper'>
                                        <img src={contestData.team2_img.S} className="__illustration"></img>
                                        {/* <div className="group_below">
                                        <div className="button-group">
                                        <button onClick={() => handleProductButtonClick2(0)} className={`productbutton${selectedProductCh2 === 0 ? ' pselected' : ''}`}>
                                            <img src="../assets/images/aocshirt.jpeg" alt="Product 1" width="125" height="125" style={{objectFit: 'cover'}}></img>
                                            T-shirt: $27
                                        </button>
                                        <button onClick={() => handleProductButtonClick2(1)} className={`productbutton${selectedProductCh2 === 1 ? ' pselected' : ''}`}>
                                            <img src="../assets/images/aochat.jpeg" alt="Product 2" width="125" height="125" style={{objectFit: 'cover'}}></img>
                                            Hat: $27
                                        </button>
                                        <button onClick={() => handleProductButtonClick2(2)} className={`productbutton${selectedProductCh2 === 2 ? ' pselected' : ''}`}>
                                            <img src="../assets/images/aoccase.jpeg" alt="Product 3" width="125" height="125" style={{objectFit: 'cover'}}></img>
                                            Case: $18
                                        </button>
                                        <button onClick={() => handleProductButtonClick2(3)} className={`productbutton${selectedProductCh2 === 3 ? ' pselected' : ''}`}>
                                            <img src="../assets/images/aocsticker.jpeg" alt="Product 4" width="125" height="125" style={{objectFit: 'cover'}}></img>
                                            Sticker: $4
                                        </button>
                                        </div>

                                        <div className="_bottom-btns desktop-btn" style={{opacity: '1'}}><a href={selectedLinkCh2} className="__get-btn2">Support {contestData.team2_name.S}</a></div>
                                        </div> */}
                                        <div className="group_below">
                                        <div className="button-group">
                                        {team2Products.map((product, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() => handleProductButtonClick2(index)}
                                                    className={`productbutton${selectedProductCh2 === index ? ' pselected' : ''}`}
                                                >
                                                    <img
                                                        src={product.product_image}
                                                        alt={product.product_name}
                                                        width="125"
                                                        height="125"
                                                        style={{ objectFit: 'cover' }}
                                                    />
                                                    {product.product_name}: ${product.product_price}
                                                    {product.product_variants.length > 1 && (
                                                        <select
                                                            className="form-select"
                                                            aria-label="Default select example"
                                                            onChange={(event) => handleVariantChangeCh2(event, index)}
                                                        >
                                                            {product.product_variants.map((variant, variantIndex) => (
                                                                <option 
                                                                selected={variantIndex === 1}
                                                                key={variantIndex} value={variantIndex}>
                                                                    {variant.variant_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                </button>
                                            ))}
                                        </div>

                                        <div className="_bottom-btns desktop-btn" style={{ opacity: '1' }}>
                                            <a href={selectedLinkCh2} className="__get-btn2">
                                                Support {contestData.team2_name.S}
                                            </a>
                                        </div>
                                    </div>

                                        <div className="_bottom-btns mobile-btn" style={{opacity: '1'}}><a href={selectedLinkCh2} className="__get-btn2">Support {contestData.team2_name.S}</a></div>
                                    {/* <div className="_bottom-btns" style={{opacity: '1'}}><a href="/get-a-sword" className="__get-btn2">Support TAX THE RICH QUEEN</a></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contest;