import logo from './logo.svg';
import './App.css';

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

import LandingPage from "./components/Landingpage";
import Contest from "./components/Contest";
import Dashboard from "./components/Dashboard";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <LandingPage></LandingPage>
    ),
  },
  {
    path: "/:slug",
    element: (
      <Contest></Contest>
      ),
  },
  {
    path: "/dashboard",
    element: (
      <Dashboard />
    ),
  },
]);


function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}
export default App;
