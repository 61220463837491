import React from 'react';
import { useAuth0 } from '@auth0/auth0-react'; // Import the useAuth0 hook
import './sidebar.css';

//pass in user as a prop
function Sidebar( {user} ) {
    const { logout } = useAuth0(); // Access the logout method from Auth0

    const handleLogout = () => {
        logout({ returnTo: window.location.origin });
      };

    console.log(user);
    
  return (
    <div className="sidebar">
      <div className="logo">
        <h3>Duel.Day ⚔️</h3>
      </div>
      {/* Hello username */}
        <div className="user d-flex align-items-center mb-4">
            <img src={user.picture} alt="user" className="user-image" style={{marginRight:'10px'}}/>
            <div className="user-info">
                <h4>{user.name}</h4>
                <small>$ 0.00</small>
            </div>
        </div>
        {/* End Hello username */}
      <ul className="nav">
        {/* Selected Style */}
        <li className="nav-item" style={{backgroundColor: "lightgray"}}>
          <a href="#">New Duel</a>
        </li>
        <li className="nav-item">
          <a href="#">Your Duels</a>
        </li>
        <li className="nav-item">
          <a href="#">Help</a>
        </li>
        <li className="nav-item">
            <a href="#" onClick={handleLogout}>Log Out</a> {/* Add onClick event listener */}
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
