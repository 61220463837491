import React, { useState, useEffect } from 'react';
import mixpanel from "mixpanel-browser";
import { useAuth0 } from "@auth0/auth0-react";
import { link } from 'react-router-dom';


const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <button onClick={() => loginWithRedirect()}>Log In</button>;
};


function LandingPage() {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    mixpanel.track("Page View", { page_name: "LANDING PAGE" });
  }, []);

    return <div><div><header class="site-header site-header--menu-center zuzu-header-section header-pink" id="sticky-menu">
    <div class="container-fluid">
      <nav class="navbar site-navbar">
        <div class="brand-logo">
          <a href="index.html">
            <h4 style={{color: 'white', fontFamily:'Inter, sans-serif', whiteSpace: 'nowrap'}}>DUEL.DAY ⚔️</h4>
            {/* <img src="assets/images/logo/logo-duelday2.png" alt="" class="light-version-logo" style={{minWidth: '200px'}}></img> */}
          </a>
        </div>
        <div class="menu-block-wrapper">
          <div class="menu-overlay"></div>
          <nav class="menu-block" id="append-menu-header">
            <div class="mobile-menu-head">
              <div class="go-back">
                <i class="fa fa-angle-left"></i>
              </div>
              <div class="current-menu-title"></div>
              <div class="mobile-menu-close">&times;</div>
            </div>
            <ul class="site-menu-main">
              <li class="nav-item nav-item-has-children">
                <a href="#" class="nav-link-item drop-trigger">Check our other sites 🥳<i class="fas fa-angle-down"></i></a>
                <ul class="sub-menu" id="submenu-1">
                  <li class="sub-menu--item">
                    <a href="http://famed.chat">Chat With AI Celebs 🤖</a>
                  </li>
                  <li class="sub-menu--item">
                    <a href="http://jetaime.so">Couple Portraits 💑</a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <div class="header-btn header-btn-l1 ms-auto d-none d-xs-inline-flex">
          {
            isAuthenticated ? 
            (
              <a href="/dashboard" class="zuzu-btn btn-blue rounded-pill">Dashboard</a>
            )
            : (
    <button
      className="zuzu-btn btn-blue rounded-pill"
      onClick={() => loginWithRedirect()}
    >
      Get Started
    </button>
  )
          }
        </div>
        {/* <div class="mobile-menu-trigger">
          <span></span>
        </div> */}
      </nav>
    </div>
  </header>
  </div>
  <div class="zuzu-hero-section2">
  <div class="container">
    <div class="zuzu-hero-content zuzu-hero-content2">
      <h1 class="wow fadeInUpX" data-wow-delay="0s" >Ready to Take on Your Rivals?</h1>
      <p class="wow fadeInUpX" data-wow-delay="0.25s">Get ready to show your support like never before. And when you win, imagine the look on your enemy's face as they're forced to wear your merchandise in defeat.</p>
      <div class="zuzu-hero-btn-wrap justify-content-center wow fadeInUpX" data-wow-delay="0.40s">
        {/* <a class="zuzu-btn btn-blue rounded-pill" href="contact-light.html">Create Your Duel</a> */}
        <div>
        {
            isAuthenticated ? 
            (
              <a href="/dashboard" class="zuzu-btn btn-blue rounded-pill">Create Your Duel</a>
            )
            : (
    <button
      className="zuzu-btn btn-blue rounded-pill"
      onClick={() => loginWithRedirect()}
    >
      Create Your Duel
    </button>
  )
          }
          </div>
      </div>
      <h4 class="wow fadeInUpX" style={{color: '#7067E5', marginTop: '40px', marginBottom: '-20px'}}>Join our active duels 👇</h4>
    </div>
  </div>
  <div class="zuzu-card-slider">
    <div class="zuzu-card-wrap">
      <div class="zuzu-card-thumb">
        <img src="assets/images/all-img/aocvsmusk.png" alt=""></img>
      </div>
      <div class="zuzu-card-data">
        <h3>AOC VS ELON</h3>
        <div class="zuzu-card-footer-data">
          <h4 style={{fontSize: '14px'}}>Mars Needs Congress</h4>
          <a class="zuzu-card-btn bg-primary-600" href="https://www.duel.day/elonvsaoc">Join</a>
        </div>
      </div>
    </div>
    <div class="zuzu-card-wrap">
      <div class="zuzu-card-thumb">
        <img src="assets/images/all-img/pineapple2.png" alt=""></img>
      </div>
      <div class="zuzu-card-data">
        <h3>PINEAPPLE PIZZA VS PIZZA</h3>
        <div class="zuzu-card-footer-data">
        <h4 style={{fontSize: '14px'}}>Italians, don't visit this duel!</h4>
          <a class="zuzu-card-btn bg-success-400" href="https://www.duel.day/pineapplepizzavspizza">Join</a>
        </div>
      </div>
    </div>
    <div class="zuzu-card-wrap">
      <div class="zuzu-card-thumb">
        <img src="assets/images/all-img/f22vsballoon.png" alt=""></img>
      </div>
      <div class="zuzu-card-data">
        <h3>F-22 VS CHINESE SPY BALLOON</h3>
        <div class="zuzu-card-footer-data">
          <h4 style={{fontSize: '14px'}}>Balloons Beware Jets!</h4>
          <a class="zuzu-card-btn bg-primary-600" href="https://www.duel.day/f22vschinesespyballoon">Join</a>
        </div>
      </div>
    </div>
    <div class="zuzu-card-wrap">
      <div class="zuzu-card-thumb">
        <img src="assets/images/all-img/soccer.png" alt=""></img>
      </div>
      <div class="zuzu-card-data">
        <h3>SOCCER VS FOOTBALL</h3>
        <div class="zuzu-card-footer-data">
          <h4 style={{fontSize: '14px'}}>Goalpost Identity Crisis!</h4>
          <a class="zuzu-card-btn bg-primary-600" href="https://www.duel.day/footballvssoccer">Join</a>
        </div>
      </div>
    </div>
    <div class="zuzu-card-wrap">
      <div class="zuzu-card-thumb">
        <img src="assets/images/all-img/aocvsmusk.png" alt=""></img>
      </div>
      <div class="zuzu-card-data">
        <h3>AOC VS ELON</h3>
        <div class="zuzu-card-footer-data">
          <h4 style={{fontSize: '14px'}}>Mars Needs Congress</h4>
          <a class="zuzu-card-btn bg-primary-600" href="https://www.duel.day/elonvsaoc">Join</a>
        </div>
      </div>
    </div>
    <div class="zuzu-card-wrap">
      <div class="zuzu-card-thumb">
        <img src="assets/images/all-img/pineapple2.png" alt=""></img>
      </div>
      <div class="zuzu-card-data">
        <h3>PINEAPPLE PIZZA VS PIZZA</h3>
        <div class="zuzu-card-footer-data">
        <h4 style={{fontSize: '14px'}}>Italians, don't visit this duel!</h4>
          <a class="zuzu-card-btn bg-success-400" href="https://www.duel.day/pineapplepizzavspizza">Join</a>
        </div>
      </div>
    </div>
    <div class="zuzu-card-wrap">
      <div class="zuzu-card-thumb">
        <img src="assets/images/all-img/f22vsballoon.png" alt=""></img>
      </div>
      <div class="zuzu-card-data">
        <h3>F-22 VS CHINESE SPY BALLOON</h3>
        <div class="zuzu-card-footer-data">
          <h4 style={{fontSize: '14px'}}>Balloons Beware Jets!</h4>
          <a class="zuzu-card-btn bg-primary-600" href="https://www.duel.day/f22vschinesespyballoon">Join</a>
        </div>
      </div>
    </div>
    <div class="zuzu-card-wrap">
      <div class="zuzu-card-thumb">
        <img src="assets/images/all-img/soccer.png" alt=""></img>
      </div>
      <div class="zuzu-card-data">
        <h3>SOCCER VS FOOTBALL</h3>
        <div class="zuzu-card-footer-data">
          <h4 style={{fontSize: '14px'}}>Goalpost Identity Crisis!</h4>
          <a class="zuzu-card-btn bg-primary-600" href="https://www.duel.day/footballvssoccer">Join</a>
        </div>
      </div>
    </div>
  </div>
</div></div>;
  }

  export default LandingPage;